/**
 * @fileoverview Define all data and data-interactions for claims
 *
 * @author Sophia Frankel <sophia.frankel@quavo.com>
 */

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { decode } from 'js-base64';

import ClaimService from '@/services/ClaimService.js';
import TaskService from '@/services/TaskService.js';
import router from '@/router'

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const readFileAsync = (file) => {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsDataURL(file);
	})
}

const maskAccountNumbers = (claim) => {
	// Mask all values
	// Expose the first 6 digits and the last 4 digits
	let regex = new RegExp("(?<=^.{6})(.*)(?=\\d{4})");

	function conversionFunction(match) {
		return '*'.repeat(match.length);
	}

	const accountNumber = claim.accountNumber || claim.AccountNumber;

	// The list and retrieve API's aren't consistant with casing on these params, setting a consistant value
	delete claim.accountNumber;
	delete claim.AccountNumber;
	if (accountNumber) {
		claim.accountNumber = accountNumber.replace(regex, conversionFunction);

		// Store last four digits as sometimes that's all the should be displayed
		claim.lastFour = claim.CardNumber ? claim.CardNumber.substring(claim.CardNumber.length - 4) : accountNumber.substring(accountNumber.length - 4);
	}

	return claim;
}

const convertDateFormat = (data, dateField) => {
	const date = dayjs.tz(data[dateField], 'YYYYMMDDTHHmmss.SSS', 'GMT');
	// Convert to localized format
	data.displayDate = date.tz().format('L LT');
	return data;
}

const formatQuestionnaireAnswers = (answers) => {
	// format the answers in a way the API expects
	const QuestionList = Object.keys(answers).map(answer => {
		const answerValue = answers[answer];
		// certain types like checkboxes need multiple answers for one identifier
		if (Array.isArray(answerValue)) {
			return answerValue.map(value => ({ Identifier: answer, AnswerValue: value }))
		}
		return { Identifier: answer, AnswerValue: answerValue }
	});
	return { QuestionList: QuestionList.flat() };
}


const intakeInitialState = {
	appliedCredits: [],
	attachment: {},
	attachments: [],
	claims: {},
	claimDetails: {},
	claimList: [],	// list of claims
	selectedClaim: '', // claim being viewed
	correspondence: '', // specific correspondence that is being viewed
	investigationDetailsList: [], // list of investigationDetails
	notesList: [], // list of notes
	recoveryDetails: [],
	reopenSummary: {},
	transactionDetails: {},
	intake: {
		cancelReason: '',
		checkDeposits: [],
		confirmationDocument: {},
		confirmationMessage: '',
		correspondenceChannels: [],
		customer: undefined,
		customers: [],
		docRequirements: undefined,
		docs: [],
		otherAccounts: [],
		prepopulatedData: {},
		postQuestionnaire: {},
		questionnaire: {},
		questionnaireAnswers: {},
		recognizedTransactions: [],
		selectedAccount: [],
		selectedCustomer: [],
		selectedOtherAccount: '',
		selectedTransactions: [],
		similarTransactions: [],
		summary: {},
		summaryAddress: {},
		summaryAnswers: {},
		summaryChannel: '',
		summaryEmailAddress: '',
		summarySendInfo: 'Yes',
		transactionDetails: {}, // account list
		reasons: {}, // answers to the claim reason questionnaire
		transactions: [], // customer list
		accounts: [], // reason questions for new claim creation
		reasonAnswers: {}, // transactions currently selected by user
		selectedSimilarTransactions: [], // transactions for claim
		transactionsStatusCode: '',
	}
};


export const state = {
	appliedCredits: [],
	attachment: {},
	attachments: [],
	claims: {},
	claimDetails: {},
	claimList: [],	// list of claims
	selectedClaim: '', // claim being viewed
	correspondence: '', // specific correspondence that is being viewed
	investigationDetailsList: [], // list of investigationDetails
	notesList: [], // list of notes
	recoveryDetails: [],
	reopenSummary: {},
	transactionDetails: {},
	intake: structuredClone(intakeInitialState),
}

/*
	Actions hook up to the interface, but they do not touch the store data directly.
	They can be rather complicated and will generally lean on one or more mutations.
	Actions are also the communication layer with external sources (API).

	Actions come with access to four contexts ({ state, getters, commit, dispatch })
		state = direct data access... avoid use
		getters = indirect data access... this is good
		commit = call a mutation
		dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
	/**
	 * Retrieve the claim list
	 * @dispatch maskAccountNumbers
	 * @param {*} contexts -  dispatch
	 */
	async retrieveClaimList({ commit, dispatch }, params) {
		await ClaimService.retrieveClaimList(params, dispatch).then((response) => {
			const claimList = response.data.claimListResponse;
			const updatedClaimList = claimList.map(claim => maskAccountNumbers(claim));
			commit('SET_CLAIM_LIST', updatedClaimList);
		}).catch(() => {
			commit('SET_CLAIM_LIST', []);
		});
	},
	/**
	 * Retrieve a claim
	 * @param {*} contexts -  dispatch
	 * @param {String} claimId - claim id 
	 */
	async retrieveClaim({ commit, dispatch }, claimId) {
		await ClaimService.retrieveClaim(claimId, dispatch).then((response) => {
			const claim = response.data;
			commit('ADD_CLAIM', maskAccountNumbers(claim));
		});
	},
	/**
	 * Set the selected claim
	 * @param {*} contexts - commit 
	 * @param {String} claimId - claim id 
	 */
	async setSelectedClaim({ commit, dispatch, getters }, claimId) {
		if (claimId && !(getters.getClaims[claimId] && getters.getClaims[claimId].data)) {
			await dispatch("retrieveClaim", claimId);
		}
		commit("SET_SELECTED_CLAIM", claimId);
	},
	/**
	 * Add a note to a claim
	 * @param {*} contexts - commit 
	 * @param {String} params.ClaimId - id of claim
	 * @param {String} params.Subject - note subject
	 * @param {String} params.Note - note message
	 */
	async addNote({ dispatch }, params) {
		await ClaimService.addNote(params, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Successfully added a note to the case.',
				});
				router.push(`/case-view/${params.ClaimId}`);
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add note to the case.',
				});
			}
		});
	},

	/**
	 * Add attachments to a claim
	 * @param {*} contexts - commit 
	 * @param {Number} ClaimId - claim id
	 * @param {Array} params.files - files to attach
	 */
	async addAttachments({ dispatch }, { ClaimId, files }) {

		const AttachmentList = await Promise.all(files.map(async (file) => {
			const fileData = await readFileAsync(file.file);

			return {
				DocumentStream: fileData.split(',')[1], // Getting a dataURL string that starts off with MIME encoding information at the beginning, just need the second part
				FileName: file.file.name,
				Category: file.Category,
				Label: file.Label,
			};
		}));

		await ClaimService.addAttachments({ AttachmentList, ClaimId }, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Successfully added attachment(s) to the case.',
				});
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add attachment(s) to the case.',
				});
			}
		});
	},

	/**
	 * Retrieve attachment list
	 * @param {*} contexts - commit 
	 * @param {String} ClaimId - claim id
	 */
	async listAttachments({ commit, dispatch }, { ClaimId }) {
		await ClaimService.listAttachments({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				const AttachmentsList = response.data.AttachmentsList || [];
				const updatedAttachmentsList = AttachmentsList.map(attachment => convertDateFormat(attachment, 'CreateDateTime'));
				commit('SET_ATTACHMENTS', updatedAttachmentsList);
			} else {
				commit('SET_ATTACHMENTS', []);
			}
		});
	},

	/**
	 * Retrieve attachment
	 * @param {*} contexts -  dispatch
	 * @param {String} LinkReference - id of attachment 
	 */
	async retrieveAttachment({ commit }, LinkReference) {
		await ClaimService.retrieveAttachment(LinkReference).then((response) => {
			commit('SET_ATTACHMENT', response.data);
		}).catch(() => {
			commit('SET_ATTACHMENT', {});
		});
	},

	/**
	 * Add required documents to a claim
	 * @param {*} contexts - commit 
	 * @param {Number} ClaimId - claim id
	 * @param {Array} params.files - files to attach
	 */
	async addDocumentsToSubmittedClaim({ dispatch }, { ClaimId, files, documentIdentifier }) {

		const DocumentRequirementList = await Promise.all(files.map(async (file) => {
			const fileData = await readFileAsync(file.file);

			return {
				DocumentStream: fileData.split(',')[1], // Getting a dataURL string that starts off with MIME encoding information at the beginning, just need the second part
				FileName: file.file.name,
				Category: file.Category,
				Label: file.Label,
				Identifier: documentIdentifier,
			};
		}));

		await ClaimService.addDocumentsToSubmittedClaim({ DocumentRequirementList, ClaimId }, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Successfully added required document(s) to the case.',
				});
				router.push(`/case-view/${ClaimId}`);
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add required document(s) to the case.',
				});
			}
		});
	},

	/**
	 * Retrieve customers
	 * @param {*} contexts - commit 
	 * @param {Number} AccountNumber - account id
	 * @param {Number} CardNumber - card number
	 * @param {Number} CustomerId - customer id
	 * @param {Number} SSNTIN - SSN / TIN
	 */
	async retrieveCustomers({ commit, dispatch }, { AccountNumber, Address, CardNumber, ClientId, CustomerId, Name, SSNTIN }) {
		await ClaimService.retrieveCustomers({
			AccountNumber, Address, CardNumber, ClientId, CustomerId, Name, SSNTIN,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_CUSTOMERS', response.data.CustomerList);
			} else {
				commit('SET_CUSTOMERS', []);
			}
		});
	},

	/**
	 * Set the customer id currently selected in the UI
	 * @param {*} contexts - commit 
	 * @param {Number} customer - customer object
	 */
	setSelectedCustomer({ commit }, { customer }) {
		commit('SET_SELECTED_CUSTOMER', customer);
	},

	/**
	 * Set the customer id
	 * @param {*} contexts - commit 
	 * @param {Number} customerId - customer id
	 */
	setCustomer({ commit }, { customerId }) {
		commit('SET_CUSTOMER', customerId);
	},

	/**
	 * Retrieve accounts
	 * @param {*} contexts - commit 
	 * @param {Number} CustomerId - customer id
	 */
	async retrieveAccounts({ commit, dispatch }, { CustomerId }) {
		await ClaimService.retrieveAccounts({
			CustomerId
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_ACCOUNTS', response.data.AccountList);
			} else {
				commit('SET_ACCOUNTS', []);
			}
		});
	},

	/**
	 * Set the account currently selected in the UI
	 * @param {*} contexts - commit 
	 * @param {Number} account - account object
	 */
	setSelectedAccount({ commit }, { account }) {
		commit('SET_SELECTED_ACCOUNT', account);
	},

	/**
	 * Retrieve transactions list
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveTransactionsList({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveTransactionsList({
			ClaimId
		}, dispatch).then((response) => {
			commit('SET_TRANSACTIONS_STATUS_CODE', response.data.StatusList[0].Code);
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_TRANSACTIONS', { ClaimId, transactions: response.data.TransactionList });
				commit('SET_SIMILAR_TRANSACTIONS', []);
			} else {
				commit('SET_TRANSACTIONS', { ClaimId, transactions: [] });
			}
		});
	},

	/**
	 * Retrieve transactions details
	 * @param {*} contexts - commit
	 * @param {String} ClaimId - claim id
	 * @param {String} DisputeId - dispute id
	 */
	async retrieveTransactionDetails({ commit, dispatch }, { ClaimId, DisputeId }) {
		await ClaimService.retrieveTransactionDetails({
			ClaimId, DisputeId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_TRANSACTION_DETAILS', response.data.TransactionData);
			} else {
				commit('SET_TRANSACTION_DETAILS', {});
			}
		});
	},

	/**
	 * Create a new claim
	 * @param {*} contexts - commit 
	 * @param {String} AccountNumber - account id
	 * @param {String} CustomerId - customer id
	 */
	async createClaim({ dispatch }, { AccountNumber, CustomerId }) {
		await ClaimService.createClaim({
			AccountNumber, CustomerId,
		}, dispatch).then(async (response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				await dispatch("setSelectedClaim", response.data.ClaimId);
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to create claim.',
				});
			}
		});
	},

	/**
	 * Get the reason questions for a new claim
	 * @param {*} contexts - commit 
	 * @param {String} ClaimId - claim id
	 */
	async retrieveReasons({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveReasons({
			ClaimId,
		}, dispatch).then((response) => {
			commit('SET_REASONS', response.data.Questionnaire);
		}).catch(() => {
			commit('SET_REASONS', {});
		});
	},

	/**
	 * Set the answer for claim reason questionnaire
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	setReasonAnswer({ commit }, { questionId, answer }) {
		commit('SET_REASON_ANSWER', { questionId, answer });
	},

	/**
	 * Removes the answer for claim reason questionnaire
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 */
	unsetReasonAnswer({ commit }, { questionId }) {
		commit('UNSET_REASON_ANSWER', { questionId });
	},

	/**
	 * Set the answer for intake questionnaire
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	setQuestionnaireAnswer({ commit }, { questionId, answer }) {
		commit('SET_QUESTIONNAIRE_ANSWER', { questionId, answer });
	},

	/**
	 * Removes the answer for intake questionnaire
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 */
	unsetQuestionnaireAnswer({ commit }, { questionId }) {
		commit('UNSET_QUESTIONNAIRE_ANSWER', { questionId });
	},

	/**
	 * Set the answer for intake summary
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	setSummaryAnswer({ commit }, { questionId, answer }) {
		commit('SET_SUMMARY_ANSWER', { questionId, answer });
	},

	/**
	 * Removes the answer for intake summary
	 * @param {*} contexts - commit 
	 * @param {String} questionId - identifier of question
	 */
	unsetSummaryAnswer({ commit }, { questionId }) {
		commit('UNSET_SUMMARY_ANSWER', { questionId });
	},

	setSummarySendInfo({ commit }, value) {
		commit('SET_SUMMARY_SEND_INFO', value);
	},

	setSummaryChannel({ commit }, value) {
		commit('SET_SUMMARY_CHANNEL', value);
	},

	setSummaryAddress({ commit }, value) {
		commit('SET_SUMMARY_ADDRESS', value);
	},

	setSummaryEmailAddress({ commit }, value) {
		commit('SET_SUMMARY_EMAIL_ADDRESS', value);
	},

	/**
	 * Add reasons to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} answers answers to reason questionnaire
	 */
	async addClaimReasons({ dispatch }, { ClaimId, answers }) {
		const Questionnaire = formatQuestionnaireAnswers(answers);

		await ClaimService.addClaimReasons({
			ClaimId, Questionnaire,
		}, dispatch).then((response) => {
			if (!(response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success')) {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add reasons to claim.',
				});
			}
		});
	},

	/**
	 * Retrieve claim intake transactions
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} answers answers to reason questionnaire
	 */
	async retrieveTransactions({ commit, dispatch }, { ClaimId, SearchStartDate, SearchEndDate, SearchAmount }) {
		await ClaimService.retrieveTransactions({
			ClaimId, SearchStartDate, SearchEndDate, SearchAmount
		}, dispatch).then((response) => {
			commit('SET_TRANSACTIONS_STATUS_CODE', response.data.StatusList[0].Code);
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_TRANSACTIONS', { ClaimId, transactions: response.data.TransactionList });
				commit('SET_SIMILAR_TRANSACTIONS', []);
			} else {
				commit('SET_TRANSACTIONS', { ClaimId, transactions: [] });
			}
		});
	},

	/**
	 * Retrieve similar claim transactions
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} answers answers to reason questionnaire
	 */
	async retrieveSimilarTransactions({ commit, dispatch }, { ClaimId, SearchAccountNumber, SearchStartDate, SearchEndDate }) {
		await ClaimService.retrieveSimilarTransactions({
			ClaimId, SearchAccountNumber, SearchStartDate, SearchEndDate,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_SIMILAR_TRANSACTIONS', response.data.TransactionList);
			} else {
				commit('SET_SIMILAR_TRANSACTIONS', []);
			}
		});
	},

	/**
	 * Set the selected transactions
	 * @param {*} contexts - commit 
	 * @param {Array} term - transactions
	 */
	setSelectedTransactions({ commit }, transactions) {
		commit("SET_SELECTED_TRANSACTIONS", transactions);
	},

	/**
	 * Set the selected similar transactions
	 * @param {*} contexts - commit 
	 * @param {Array} term - transactions
	 */
	setSelectedSimilarTransactions({ commit }, transactions) {
		commit("SET_SELECTED_SIMILAR_TRANSACTIONS", transactions);
	},

	/**
	 * Set the recognized transactions
	 * @param {*} contexts - commit 
	 * @param {Array} term - transactions
	 */
	setRecognizedTransactions({ commit }, transactions) {
		commit("SET_RECOGNIZED_TRANSACTIONS", transactions);
	},

	/**
	 * Set check deposits
	 * @param {*} contexts - commit 
	 * @param {Array} deposits - deposits
	 */
	setCheckDeposits({ commit }, deposits) {
		commit("SET_CHECK_DEPOSITS", deposits);
	},

	/**
	 * Add transactions to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} transactions selected transactions
	 */
	async addClaimTransactions({ dispatch }, { ClaimId, transactions }) {
		// format the transactions in a way the API expects
		const TransactionList = transactions.map(transaction => ({ TransactionId: transaction.TransactionId }));

		await ClaimService.addClaimTransactions({
			ClaimId, TransactionList,
		}, dispatch).then((response) => {
			if (!(response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success')) {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add transactions to claim.',
				});
			}
		});
	},

	/**
	 * Add similar transactions to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} transactions selected transactions
	 */
	async addSimilarTransactions({ dispatch }, { ClaimId, transactions }) {
		// format the transactions in a way the API expects
		const TransactionList = transactions.map(transaction => ({ TransactionId: transaction.TransactionId }));

		await ClaimService.addSimilarTransactions({
			ClaimId, TransactionList,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				// nothing happens on success
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add similar transactions to claim.',
				});
			}
		});
	},

	/**
	 * Add recognized transactions to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} transactions selected transactions
	 */
	async recognizeDisputedTransactions({ dispatch }, { ClaimId, transactions }) {
		// format the transactions in a way the API expects
		const TransactionList = transactions.map(transaction => ({ TransactionId: transaction }));

		await ClaimService.recognizeDisputedTransactions({
			ClaimId, TransactionList,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				// nothing happens on success
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add recognized transactions to claim.',
				});
			}
		});
	},

	/**
	 * Add check deposits to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} CheckDepositList check deposits
	 */
	async addCheckDeposits({ dispatch }, { ClaimId, CheckDepositList }) {
		await ClaimService.addCheckDeposits({
			ClaimId, CheckDepositList,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				// nothing happens on success
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add check deposits to claim.',
				});
			}
		});
	},

	/**
	 * Retrieve intake questionnaire
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveQuestionnaire({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveQuestionnaire({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_QUESTIONNAIRE', response.data.Questionnaire || {});
				commit('SET_POST_QUESTIONNAIRE', response.data.PostQuestionnaire || {});
				commit('SET_INTAKE_TRANSACTION_DETAILS', response.data.TransactionDetails || {});
			} else {
				commit('SET_QUESTIONNAIRE', {});
				commit('SET_POST_QUESTIONNAIRE', {});
				commit('SET_INTAKE_TRANSACTION_DETAILS', {});
			}
		});
	},

	/**
	 * Retrieve other accounts
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveOtherAccounts({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveOtherAccounts({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_OTHER_ACCOUNTS', response.data.AccountList);
			} else {
				commit('SET_OTHER_ACCOUNTS', []);
			}
		});
	},

	/**
	 * Set the selected other account
	 * @param {*} contexts - commit 
	 * @param {String} otherAccount - other account number
	 */
	setSelectedOtherAccount({ commit }, otherAccount) {
		commit("SET_SELECTED_OTHER_ACCOUNT", otherAccount);
	},

	/**
	 * Add questionnaire answers to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} answers answers to intake questionnaire
	 */
	async addQuestionnaire({ dispatch }, { ClaimId, answers }) {
		const Questionnaire = formatQuestionnaireAnswers(answers);

		await ClaimService.addQuestionnaire({
			ClaimId, Questionnaire,
		}, dispatch).then(async (response) => {
			if (!(response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success')) {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add questionnaire answers to claim.',
				});
			}
		});
	},

	/**
	 * Retrieve document requirements
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveDocRequirements({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveDocRequirements({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Code == '200') {
				commit('SET_DOC_REQUIREMENTS', response.data.DocumentRequirementList);
				commit('SET_INTAKE_TRANSACTION_DETAILS', response.data.TransactionDetails || {});
			} else if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Code == '201') { // successful result, but no docs required
				commit('SET_DOC_REQUIREMENTS', false);
				commit('SET_INTAKE_TRANSACTION_DETAILS', {});
			} else {
				commit('SET_DOC_REQUIREMENTS', []);
				commit('SET_INTAKE_TRANSACTION_DETAILS', {});
			}
		});
	},

	/**
	 * Add documents to claim
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {String} Identifier document id
	 * @param {String} AttachmentCategory document category
	 * @param {String} AttachmentLabel document label
	 * @param {File} file file to be attached
	 */
	async addDocuments({ commit, dispatch }, { ClaimId, Identifier, AttachmentCategory, AttachmentLabel, file }) {

		const fileData = await readFileAsync(file);

		const DocumentRequirementList = [{
			DocumentStream: fileData.split(',')[1], // Getting a dataURL string that starts off with MIME encoding information at the beginning, just need the second part
			Identifier,
			AttachmentCategory,
			FileName: file.name,
			AttachmentLabel,
		}];

		await ClaimService.addDocuments({
			ClaimId, DocumentRequirementList,
		}, dispatch).then(async (response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('STORE_DOC', { Identifier, AttachmentLabel, FileName: file.name, Document: fileData });
				// refresh the requirements list
				// dispatch("retrieveDocRequirements", { ClaimId }); // don't do this yet, as currently the API is removing the docs after adding them
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to add questionnaire answers to claim.',
				});
			}
		});
	},

	/**
	 * Retrieve intake summary
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveSummary({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveSummary({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_INTAKE_TRANSACTION_DETAILS', response.data.TransactionDetails);
				let strippedSummary = response.data;
				strippedSummary.TransactionDetails = {};
				commit('SET_SUMMARY', strippedSummary);
			} else {
				commit('SET_INAKE_TRANSACTION_DETAILS', {});
				commit('SET_SUMMARY', {});
			}
		});
	},

	/**
	 * Submit intake
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 * @param {Object} answers answers to intake questionnaire
	 * @param {Object} CorrespondenceDetails object containing contact information
	 */
	async intakeSubmit({ dispatch }, { ClaimId, answers, CorrespondenceDetails }) {
		const Questionnaire = formatQuestionnaireAnswers(answers);

		await ClaimService.intakeSubmit({
			ClaimId, Questionnaire, CorrespondenceDetails
		}, dispatch).then((response) => {
			if (!(response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success')) {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to submit claim.',
				});
			}
		});
	},

	/**
	 * Retrieve intake confirmation
	 * @param {*} contexts - commit
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveConfirmation({ commit, dispatch }, { ClaimId }) {
		await ClaimService.retrieveConfirmation({
			ClaimId,
		}, dispatch).then((response) => {
			if (response && response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_CONFIRMATION_MESSAGE', decode(response.data.ConfirmationMessageMarkup));
				commit('SET_CONFIRMATION_DOCUMENT', { name: response.data.ConfirmationDocumentName, data: response.data.ConfirmationDocumentStream })
			} else {
				commit('SET_CONFIRMATION_MESSAGE', '');
				commit('SET_CONFIRMATION_DOCUMENT', {});
			}
		});
	},

	/**
	 * Request withdrawing a claim
	 * @param {*} contexts - commit 
	 * @param {String} ClaimId - id of claim
	 * @param {String} WithdrawalReason - withdrawal reason
	 */
	async requestWithdraw({ dispatch }, { ClaimId, WithdrawalReason, transactions = [] }) {
		// format the transactions in a way the API expects
		const TransactionList = transactions.map(transaction => ({ TransactionId: transaction.TransactionId }));

		await ClaimService.requestWithdraw({ ClaimId, WithdrawalReason, TransactionList }, dispatch).then((response) => {
			if (response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Successfully sent withdrawal request.',
				});
				router.push(`/case-view/${ClaimId}`);
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to submit withdraw request.',
				});
			}
		});
	},

	setIntakeCancelReason({ commit }, { reason }) {
		commit("SET_INTAKE_CANCEL_REASON", { reason });
	},

	/**
	 * Cancel an intake claim
	 * @param {*} contexts - commit 
	 * @param {String} ClaimId - id of claim
	 * @param {String} CancelClaimReason - cancel reason
	 */
	async cancelIntake({ dispatch }, { ClaimId, CancelClaimReason }) {
		await ClaimService.cancelIntake({ ClaimId, CancelClaimReason }, dispatch).then((response) => {
			if (response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Successfully sent cancel request.',
				});
				dispatch("clearClaimDetails");
                dispatch("removeNavItem", '/create-claim/');
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to submit cancel request.',
				});
			}
		});
	},

	setIntakePrepopulatedData({ commit }, data) {
		commit('SET_INTAKE_PREPOPULATED_DATA', data);
	},

	/**
	 * Request to reopen dispute
	 * @param {*} contexts - commit 
	 * @param {String} ClaimId - id of claim
	 * @param {String} ReopenReason - reopen reason
	 * @param {String} ReopenNewInformation - new information to provide
	 */
	async reopenRequest({ commit, dispatch }, { ClaimId, ReopenReason, ReopenNewInformation, transactions }) {
		// format the transactions in a way the API expects
		const TransactionList = transactions.map(transaction => ({ TransactionId: transaction.TransactionId }));

		await ClaimService.reopenRequest({ ClaimId, ReopenReason, ReopenNewInformation, TransactionList }, dispatch).then((response) => {
			if (response.data && response.data.StatusList && response.data.StatusList[0] && response.data.StatusList[0].Type == 'Success') {
				commit('SET_REOPEN_SUMMARY', response.data);
			} else {
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'Failed to submit reopen request.',
				});
				commit('SET_REOPEN_SUMMARY', {});
			}
		});
	},

	/**
	 * Retrieve the correspondence list
	 * @param {*} contexts -  dispatch
	 * @param {String} claimId - claim id 
	 */
	async retrieveCorrespondenceList({ commit }, ClaimId) {
		var finalCorrespondenceList = [];





		await ClaimService.retrieveCorrespondenceList(ClaimId).then((corrResponse) => {
			const correspondenceList = corrResponse.data.CorrespondenceList;
			var updatedCorrespondenceList = correspondenceList.map(correspondence => convertDateFormat(correspondence, 'SaveDate'));
			finalCorrespondenceList = finalCorrespondenceList.concat(updatedCorrespondenceList);
		}).catch(() => {
			commit('SET_CORRESPONDENCE_LIST', { ClaimId, correspondence: [] });
		});

		await ClaimService.retrieveNotesList(ClaimId).then((notesList) => {
			const noteList = notesList.data.NotesList;
			var messageList = noteList.filter(note => note.Visibility.Value == "Member");
			messageList = messageList.map(message => convertDateFormat(message, 'CreateDateTime'));
			messageList = messageList.map(message => {
				return {
					TransmissionStatus: "Sent",
					Document: "Message",
					ThreadSubject: message.Subject,
					displayDate: message.displayDate,
					LinkReference: message.LinkReference,
					CreateName: message.CreateName,
				};
			});
			finalCorrespondenceList = finalCorrespondenceList.concat(messageList);
		}).catch(() => {
			commit('SET_CORRESPONDENCE_LIST', { ClaimId, correspondence: [] });
		});

		commit('SET_CORRESPONDENCE_LIST', { ClaimId, correspondence: finalCorrespondenceList });
	},
	/**
	 * Retrieve the correspondence list
	 * @param {*} contexts -  dispatch
	 * @param {String} LinkReference - id of correspondence 
	 */
	async retrieveCorrespondence({ commit }, LinkReference) {
		var corrType = LinkReference.split(' ')[0];
		var response;
		var Body;
		var EmailStyleMarkup;
		var AttachmentAvailable;
		if (corrType == 'DATA-WORKATTACH-NOTE') {
			response = await ClaimService.retrieveNote(LinkReference).catch(() => { commit('SET_CORRESPONDENCE', {}) });
			Body = response.data.Note.replace(/\\n/g, ' ').replace(/\\"/g, '"');
		} else {
			response = await ClaimService.retrieveCorrespondence(LinkReference).catch(() => { commit('SET_CORRESPONDENCE', {}) });
			// The API is returning the data in an escaped format. Need to undo this.
			Body = response.data.Body.replace(/\\n/g, '').replace(/\\"/g, '"');
			EmailStyleMarkup = response.data.EmailStyleMarkup.replace(/\\n/g, '');
			AttachmentAvailable = response.data.AttachmentAvailable;
		}

		commit('SET_CORRESPONDENCE', { Body, EmailStyleMarkup, AttachmentAvailable });
	},
	/**
	 * Retrieve info on where to send correspondence
	 * @param {*} contexts -  dispatch
	 * @param {Number} ClaimId - claim id
	 */
	async retrieveCorrespondenceChannels({ commit }, LinkReference) {
		await ClaimService.retrieveCorrespondenceChannels(LinkReference).then((response) => {
			commit('SET_CORRESPONDENCE_CHANNELS', response.data.CorrespondenceChannelList);
			commit('SET_INTAKE_TRANSACTION_DETAILS', response.data.TransactionDetails);

		}).catch(() => {
			commit('SET_CORRESPONDENCE_CHANNELS', []);
			commit('SET_INTAKE_TRANSACTION_DETAILS', {});
		});
	},

	/**
	 * Retrieve the Investigation details
	 * @param {*} contexts -  dispatch
	 * @param {String} claimId - claim id 
	 */
	async retrieveInvestigationDetails({ commit }, ClaimId) {
		await ClaimService.retrieveInvestigationDetails(ClaimId).then((response) => {
			const investigationTopicList = response.data.InvestigationTopicList;
			const updatedInvestigationTopicList = investigationTopicList.map(investigationTopic => convertDateFormat(investigationTopic, 'EnteredOnDateTime'));
			commit('SET_INVESTIGATIONDETAILS_LIST', updatedInvestigationTopicList);
		}).catch(() => {
			commit('SET_INVESTIGATIONDETAILS_LIST', []);
		});
	},

	/**
	 * Retrieve the notes list
	 * @param {*} contexts -  dispatch
	 * @param {String} claimId - claim id 
	 */
	async retrieveNotesList({ commit }, ClaimId) {
		await ClaimService.retrieveNotesList(ClaimId).then((response) => {
			const notesList = response.data.NotesList;
			const updatedNotesList = notesList.map(note => convertDateFormat(note, 'CreateDateTime'));
			commit('SET_NOTES_LIST', updatedNotesList);
		}).catch(() => {
			commit('SET_NOTES_LIST', []);
		});
	},

	async sendMessage({ dispatch }, { Subject, Message, ClaimId }) {
		if (Subject && Message) {
			await ClaimService.sendMessage(ClaimId, Subject, Message).then(() => {
				dispatch("notifications/addNotification", {
					status: 'success',
					message: 'Thank you for your message.',
				});
				dispatch("retrieveCorrespondenceList", ClaimId);
			});
		} else {
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'Please provide a subject and a message.',
			});
		}
	},

	/**
	 * Retrieve recovery details for a dispute
	 * @param {*} contexts -  dispatch
	 * @param {String} DisputeId - dispute id 
	 */
	async retrieveRecoveryDetails({ commit }, { DisputeId }) {
		await ClaimService.retrieveRecoveryDetails({ DisputeId }).then((response) => {
			commit('SET_RECOVERY_DETAILS', response.data.recoveryDetailsList || []);
		}).catch(() => {
			commit('SET_RECOVERY_DETAILS', []);
		});
	},

	/**
	 * Retrieve claim details for a dispute
	 * @param {*} contexts -  dispatch
	 * @param {String} ClaimId - claim id 
	 */
	async retrieveClaimDetails({ commit }, ClaimId) {
		await ClaimService.retrieveClaimDetails(ClaimId).then((response) => {
			commit('SET_CLAIM_DETAILS', response.data || []);
		}).catch(() => {
			commit('SET_CLAIM_DETAILS', []);
		});
	},

	async retrieveAppliedCredits({ commit }, { ClaimId, DisputeId }) {
		await ClaimService.retrieveAppliedCredits({ DisputeId }).then((response) => {
			commit('SET_APPLIED_CREDITS', { ClaimId, value: response.data.AppliedMerchantCredits || [] });
		}).catch(() => {
			commit('SET_APPLIED_CREDITS', { ClaimId, value: [] });
		});
	},
	async retrieveClaimTaskList({ commit }, { ClaimId }) {
		await TaskService.retrieveClaimTaskList({ ClaimId }).then((response) => {
			const TaskList = response.data.TaskList;
			const UpdatedTaskList = TaskList.map(task => convertDateFormat(task, 'CreateDateTime'));
			commit("SET_TASK_LIST", { ClaimId, taskList: UpdatedTaskList });
		}).catch(() => {
			commit("SET_TASK_LIST", { ClaimId, taskList: [] });
		});
	},

	/**
	 * Add a navigation item to the nav list
	 * @param {*} contexts - commit, getters 
	 * @param {Object} item - navigation item 
	 */
	async setOpenMessage({ commit }, { ClaimId, messageId }) {
		commit('SET_OPEN_MESSAGE', { ClaimId, messageId });
	},
	async setOpenTask({ commit, getters }, { ClaimId, TaskLabel }) {
		router.push(`${getters.getIsEmbedded ? '/embedded' : ''}/case-view/${ClaimId}`);
		await commit('SET_SELECTED_CLAIM', ClaimId);
		await commit('SET_OPEN_TASK', { ClaimId, TaskLabel });
		await commit('SET_CLAIM_TAB', { ClaimId, tab: 'tasks' });

	},
	async setClaimTab({ commit }, { ClaimId, tab }) {
		commit('SET_CLAIM_TAB', { ClaimId, tab });
	},
	/**
	 * Handles the various case actions being selected
	 * @param {*} contexts - commit 
	 * @param {String} action - action name 
	 */
	// eslint-disable-next-line no-empty-pattern
	triggerCaseAction({ }, { action, actionId, claimId }) {
		switch (action) {
			case 'Add a note':
				router.push(`/case-view/${claimId}/addNote`);
				break;
			case 'Request case is withdrawn':
				router.push(`/case-view/${claimId}/requestWithdrawn`);
				break;
			case 'Request dispute is reopened':
				router.push(`/case-view/${claimId}/requestReopened`);
				break;
			case 'Upload a new file':
				router.push(`/case-view/${claimId}/uploadFile`);
				break;
			case 'Upload required documentation':
				router.push(`/case-view/${claimId}/uploadDocumentation`);
				break;
			case 'View Case Attachments':
				router.push(`/case-view/${claimId}/viewCaseAttachments`);
				break;
			case 'View Claim Details':
				router.push(`/case-view/${claimId}/viewClaimDetails`);
				break;
			case 'View Communication':
				router.push(`/case-view/${claimId}/viewCommunicationList`);
				break;
			case 'View Investigation Details':
				router.push(`/case-view/${claimId}/viewInvestigationDetails`);
				break;
			case 'View Message':
				router.push(`/case-view/${claimId}/viewMessage/${actionId}`);
				break;
			case 'View Attachment':
				router.push(`/case-view/${claimId}/viewAttachment/${actionId}`);
				break;
			default:
				router.push(`/case-view/${claimId}`);
		}
	},
	/**
	 * Clears stored claim details
	 * @param {*} contexts - commit 
	 */
	clearClaimDetails({ commit, dispatch }) {
		commit("CLEAR_CUSTOMERS");
		commit("CLEAR_SELECTED_CUSTOMER");
		commit("CLEAR_SELECTED_ACCOUNT");
		commit("CLEAR_SELECTED_CLAIM");
		commit("CLEAR_SELECTED_TRANSACTIONS");
		commit("CLEAR_REASON_ANSWERS");
		commit("CLEAR_QUESTIONNAIRE_ANSWERS");
		commit("CLEAR_SUMMARY_ANSWERS");
		commit("CLEAR_DOC_REQUIREMENTS");
		commit("CLEAR_STORED_DOCS");
		commit("CLEAR_CHECK_DEPOSITS");
		dispatch("resetIntakeSteps");
	},

};

/*
	Mutations are methods that act on the state data directly.
	They should be incredibly basic and perform a single task.

	Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
	/**
	 * Set the claim list
	 * @param {*} state - current state
	 * @param {Array} list - claim list
	 */
	SET_CLAIM_LIST(state, list) {
		state.claimList = list;
	},
	/**
	 * Store a claim
	 * @param {*} state - current state
	 * @param {Object} claim - claim
	 */
	ADD_CLAIM(state, claim) {
		const { ClaimId } = claim;

		const currentClaim = state.claims[ClaimId] || {};

		const updatedClaim = {
			...currentClaim,
			data: claim,
		};

		state.claims = {
			...state.claims,
			[ClaimId]: updatedClaim
		}
	},
	/**
	 * Set the selected claim
	 * @param {*} state - current state 
	 * @param {Object} claimId - selected claim id 
	 */
	SET_SELECTED_CLAIM(state, claimId) {
		state.selectedClaim = claimId;
	},
	/**
	 * Clear the selected claim
	 * @param {*} state - current state 
	 */
	CLEAR_SELECTED_CLAIM(state) {
		state.selectedClaim = '';
	},
	RESET_INTAKE(state) {
		state.intake = structuredClone(intakeInitialState);
	},
	/**
	 * Set the answer for claim reason questionnaire
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	SET_REASON_ANSWER(state, { questionId, answer }) {
		state.intake.reasonAnswers[questionId] = answer;
	},
	/**
	 * Removes the answer for claim reason questionnaire
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 */
	UNSET_REASON_ANSWER(state, { questionId }) {
		delete state.intake.reasonAnswers[questionId];
	},
	/**
	 * Clear claim answers
	 * @param {*} state - current state 
	 */
	CLEAR_REASON_ANSWERS(state) {
		state.intake.reasonAnswers = {};
	},
	/**
	 * Set the answer for intake questionnaire
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	SET_QUESTIONNAIRE_ANSWER(state, { questionId, answer }) {
		state.intake.questionnaireAnswers[questionId] = answer;
	},
	/**
	 * Removes the answer for intake questionnaire
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 */
	UNSET_QUESTIONNAIRE_ANSWER(state, { questionId }) {
		delete state.intake.questionnaireAnswers[questionId];
	},
	/**
	 * Clear intake questionnaire answers
	 * @param {*} state - current state 
	 */
	CLEAR_QUESTIONNAIRE_ANSWERS(state) {
		state.intake.questionnaireAnswers = {};
	},
	/**
	 * Set the answer for intake summary
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 * @param {String} answer - value of answer
	 */
	SET_SUMMARY_ANSWER(state, { questionId, answer }) {
		state.intake.summaryAnswers[questionId] = answer;
	},
	/**
	 * Removes the answer for intake summary
	 * @param {*} state - current state 
	 * @param {String} questionId - identifier of question
	 */
	UNSET_SUMMARY_ANSWER(state, { questionId }) {
		delete state.intake.summaryAnswers[questionId];
	},
	/**
	 * Clear intake summary answers
	 * @param {*} state - current state 
	 */
	CLEAR_SUMMARY_ANSWERS(state) {
		state.intake.summaryAnswers = {};
	},
	SET_SUMMARY_SEND_INFO(state, value) {
		state.intake.summarySendInfo = value;
	},
	SET_SUMMARY_CHANNEL(state, value) {
		state.intake.summaryChannel = value;
	},
	SET_SUMMARY_ADDRESS(state, value) {
		state.intake.summaryAddress = value;
	},
	SET_SUMMARY_EMAIL_ADDRESS(state, value) {
		state.intake.summaryEmailAddress = value;
	},
	/**
	 * Set attachments list
	 * @param {*} state - current state
	 * @param {Array} attachments - attachments list
	 */
	SET_ATTACHMENTS(state, attachments) {
		state.attachments = attachments;
	},
	/**
	 * Set the correspondence
	 * @param {*} state - current state
	 * @param {Object} attachment - attachment data
	 */
	SET_ATTACHMENT(state, attachment) {
		state.attachment = attachment;
	},
	/**
	 * Set the correspondence list
	 * @param {*} state - current state
	 * @param {Array} list - correspondence list
	 */
	SET_CORRESPONDENCE_LIST(state, { ClaimId, correspondence }) {
		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { correspondence });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	/**
	 * Set the correspondence
	 * @param {*} state - current state
	 * @param {String} correspondence - correspondence
	 */
	SET_CORRESPONDENCE(state, correspondence) {
		state.correspondence = correspondence;
	},
	/**
	 * Set the correspondence channel
	 * @param {*} state - current state
	 * @param {String} correspondenceChannels - correspondence channels
	 */
	SET_CORRESPONDENCE_CHANNELS(state, correspondenceChannels) {
		state.intake.correspondenceChannels = correspondenceChannels;
	},
	/**
	 * Set the investigation details list
	 * @param {*} state - current state
	 * @param {Array} list - investigation details list
	 */
	SET_INVESTIGATIONDETAILS_LIST(state, list) {
		state.investigationDetailsList = list;
	},
	/**
	 * Set the notes list
	 * @param {*} state - current state
	 * @param {Array} list - notes list
	 */
	SET_NOTES_LIST(state, list) {
		state.notesList = list;
	},
	/**
	 * Set the recovery details
	 * @param {*} state - current state
	 * @param {Array} details - details
	 */
	SET_RECOVERY_DETAILS(state, details) {
		state.recoveryDetails = details;
	},
	/**
	 * Set the claim details
	 * @param {*} state - current state
	 * @param {Array} details - details
	 */
	SET_CLAIM_DETAILS(state, details) {
		state.claimDetails = details;
	},
	/**
	 * Set customers list
	 * @param {*} state - current state
	 * @param {Array} customers - customer list
	 */
	SET_CUSTOMERS(state, customers) {
		state.intake.customers = customers;
	},
	CLEAR_CUSTOMERS(state) {
		state.intake.customers = [];
	},
	/**
	 * Set selected customer id
	 * @param {*} state - current state
	 * @param {Number} customerId - customer id
	 */
	SET_CUSTOMER(state, customerId) {
		state.intake.customer = customerId;
	},
	CLEAR_SELECTED_CUSTOMER(state) {
		state.intake.selectedCustomer = [];
	},
	SET_SELECTED_CUSTOMER(state, customer) {
		state.intake.selectedCustomer = customer;
	},
	CLEAR_SELECTED_ACCOUNT(state) {
		state.intake.selectedAccount = [];
	},
	SET_SELECTED_ACCOUNT(state, account) {
		state.intake.selectedAccount = account;
	},
	/**
	 * Set account list
	 * @param {*} state - current state
	 * @param {Array} accounts - account list
	 */
	SET_ACCOUNTS(state, accounts) {
		state.intake.accounts = accounts;
	},
	/**
	 * Set the reasons
	 * @param {*} state - current state
	 * @param {Array} reasons - claim reasons
	 */
	SET_REASONS(state, reasons) {
		state.intake.reasons = reasons;
	},
	/**
	 * Set the transaction details
	 * @param {*} state - current state
	 * @param {Object} details - object containing the transaction details
	 */
	SET_TRANSACTION_DETAILS(state, details) {
		state.transactionDetails = details;
	},
	/**
	 * Set the transactions
	 * @param {*} state - current state
	 * @param {Array} transactions - claim transactions
	 */
	SET_TRANSACTIONS(state, { ClaimId, transactions }) {
		state.intake.transactions = transactions;

		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { transactions });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	/**
	 * Set the transactions status code
	 * @param {*} state - current state
	 * @param {Array} transactionsStatusCode - response code for retrieveTransactions call
	 */
	SET_TRANSACTIONS_STATUS_CODE(state, transactionsStatusCode) {
		state.intake.transactionsStatusCode = transactionsStatusCode;
	},
	/**
	 * Set the similar transactions
	 * @param {*} state - current state
	 * @param {Array} transactions - claim transactions
	 */
	SET_SIMILAR_TRANSACTIONS(state, transactions) {
		state.intake.similarTransactions = transactions;
	},
	/**
	 * Set the selected transactions
	 * @param {*} state - current state
	 * @param {Array} transactions - claim transactions
	 */
	SET_SELECTED_TRANSACTIONS(state, transactions) {
		state.intake.selectedTransactions = transactions;
	},
	/**
	 * Clear the selected transactions
	 * @param {*} state - current state
	 */
	CLEAR_SELECTED_TRANSACTIONS(state) {
		state.intake.selectedTransactions = [];
	},
	/**
	 * Set the selected similar transactions
	 * @param {*} state - current state
	 * @param {Array} transactions - claim transactions
	 */
	SET_SELECTED_SIMILAR_TRANSACTIONS(state, transactions) {
		state.intake.selectedSimilarTransactions = transactions;
	},
	/**
	 * Set the recognized transactions
	 * @param {*} state - current state
	 * @param {Array} transactions - claim transactions
	 */
	SET_RECOGNIZED_TRANSACTIONS(state, transactions) {
		state.intake.recognizedTransactions = transactions;
	},
	/**
	 * Set check deposits
	 * @param {*} state - current state
	 * @param {Array} deposits - check deposits
	 */
	SET_CHECK_DEPOSITS(state, deposits) {
		state.intake.checkDeposits = deposits;
	},
	/**
	 * Clear check deposits
	 * @param {*} state - current state
	 */
	CLEAR_CHECK_DEPOSITS(state) {
		state.intake.checkDeposits = [];
	},
	/**
	 * Set the questionnaire
	 * @param {*} state - current state
	 * @param {Array} questionnaire - intake questionnaire
	 */
	SET_QUESTIONNAIRE(state, questionnaire) {
		state.intake.questionnaire = questionnaire;
	},
	/**
	 * Set the post questionnaire
	 * @param {*} state - current state
	 * @param {Array} postQuestionnaire - intake post questionnaire
	 */
	SET_POST_QUESTIONNAIRE(state, postQuestionnaire) {
		state.intake.postQuestionnaire = postQuestionnaire;
	},
	/**
	 * Set the transactionDetails
	 * @param {*} state - current state
	 * @param {Array} transactionDetails - intake transactiond etails
	 */
	SET_INTAKE_TRANSACTION_DETAILS(state, transactionDetails) {
		state.intake.transactionDetails = transactionDetails;
	},
	/**
	 * Set other accounts
	 * @param {*} state - current state
	 * @param {Array} accountList - list of accounts
	 */
	SET_OTHER_ACCOUNTS(state, accountList) {
		state.intake.otherAccounts = accountList;
	},
	/**
	 * Set selected other account
	 * @param {*} state - current state
	 * @param {String} selectedOtherAccount - selected other account
	 */
	SET_SELECTED_OTHER_ACCOUNT(state, selectedOtherAccount) {
		state.intake.selectedOtherAccount = selectedOtherAccount;
	},
	/**
	 * Set summary
	 * @param {*} state - current state
	 * @param {Object} summary - summary details
	 */
	SET_SUMMARY(state, summary) {
		state.intake.summary = summary;
	},
	/**
	 * Set confirmation message
	 * @param {*} state - current state
	 * @param {Object} confirmation - confirmation message markup
	 */
	SET_CONFIRMATION_MESSAGE(state, confirmationMessage) {
		state.intake.confirmationMessage = confirmationMessage;
	},
	/**
	 * Set confirmation document
	 * @param {*} state - current state
	 * @param {Object} name - confirmation document name
	 * @param {Object} data - confirmation document data
	 */
	SET_CONFIRMATION_DOCUMENT(state, { name, data }) {
		state.intake.confirmationDocument = { name, data };
	},
	/**
	 * Set document requirements
	 * @param {*} state - current state
	 * @param {Object} requirements - document requirements
	 */
	SET_DOC_REQUIREMENTS(state, requirements) {
		state.intake.docRequirements = requirements;
	},
	/**
	 * Clear document requirements
	 * @param {*} state - current state
	 */
	CLEAR_DOC_REQUIREMENTS(state) {
		state.intake.docRequirements = undefined;
	},
	/**
	 * Store document
	 * @param {*} state - current state
	 * @param {Object} docData - document data
	 */
	STORE_DOC(state, docData) {
		state.intake.docs.push(docData);
	},
	/**
	 * clear stored documents
	 * @param {*} state - current state
	 */
	CLEAR_STORED_DOCS(state) {
		state.intake.docs = [];
	},
	/**
	 * Set the summary respone for reopen request
	 * @param {*} state - current state 
	 * @param {Object} summaryData - summary data
	 */
	SET_REOPEN_SUMMARY(state, summaryData) {
		state.reopenSummary = summaryData;
	},
	SET_OPEN_MESSAGE(state, { ClaimId, messageId }) {
		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { viewMessage: messageId });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	SET_APPLIED_CREDITS(state, { ClaimId, value }) {
		state.appliedCredits = value;

		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { appliedCredits: value });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	SET_INTAKE_CANCEL_REASON(state, { reason }) {
		state.intake.cancelReason = reason;
	},
	SET_INTAKE_PREPOPULATED_DATA(state, data) {
		state.intake.prepopulatedData = data;
	},
	SET_TASK_LIST(state, { ClaimId, taskList }) {
		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { taskList });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	SET_OPEN_TASK(state, { ClaimId, TaskLabel }) {
		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { viewTask: TaskLabel });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
	SET_CLAIM_TAB(state, { ClaimId, tab }) {
		const currentClaimData = state.claims[ClaimId] || {};

		const mergedClaimData = Object.assign({}, currentClaimData, { tab });
		state.claims = Object.assign([], state.claims, { [ClaimId]: mergedClaimData });
	},
};

/*
	Getters are non-cached access points to the state data.
	They allow us to interrogate the data and pull back dynamic subsets.
*/

export const getters = {
	getAppliedCredits: state => { return state.appliedCredits },
	/**
	 * get current loaded attachment
	 * @param {*} state - current state
	 * @returns Object of attachment data
	 */
	getAttachment: state => { return state.attachment },
	/**
	 * get attachments list
	 * @param {*} state - current state
	 * @returns Array of attachments
	 */
	getAttachments: state => { return state.attachments },
	/**
	 * get all loaded claims
	 * @param {*} state - current state
	 * @returns Array of claims
	 */
	getClaims: state => { return state.claims },
	/**
	 * get selected claim
	 * @param {*} state - current state
	 * @returns Selected claim object
	 */
	getSelectedClaim: state => {
		return state.claims[state.selectedClaim] || {};
	},
	/**
	 * get current loaded correspondence
	 * @param {*} state - current state
	 * @returns String of correspondence data
	 */
	getCorrespondence: state => { return state.correspondence },
	/**
	 * get correspondence channels
	 * @param {*} state - current state
	 * @returns String of correspondence channels
	 */
	getCorrespondenceChannels: state => { return state.intake.correspondenceChannels },
	/**
	 * get all loaded investigation details
	 * @param {*} state - current state
	 * @returns Array of investigation details
	 */
	getInvestigationDetailsList: state => { return state.investigationDetailsList },
	/**
	 * get all loaded notes
	 * @param {*} state - current state
	 * @returns Array of notes
	 */
	getNotesList: state => { return state.notesList },
	/**
	 * get recovery details
	 * @param {*} state - current state
	 * @returns Array of recovery details
	 */
	getRecoveryDetails: state => { return state.recoveryDetails },
	/**
	 * get claim details
	 * @param {*} state - current state
	 * @returns claim details
	 */

	getClaimDetails: state => {
		return state.claimDetails
	},
	/**
	 * get transaction details
	 * @param {*} state - current state
	 * @returns Object of transaction details
	 */
	getTransactionDetails: state => { return state.transactionDetails },
	/**
	 * get customers
	 * @param {*} state - current state
	 * @returns Customer list array
	 */
	getIntakeCancelReason: state => { return state.intake.cancelReason },
	getCustomers: state => { return state.intake.customers },
	/**
	 * get customer
	 * @param {*} state - current state
	 * @returns Customer id
	 */
	getIntakeCustomer: state => { return state.intake.customer },
	getSelectedCustomer: state => { return state.intake.selectedCustomer },
	/**
	 * get accounts
	 * @param {*} state - current state
	 * @returns Account list array
	 */
	getAccounts: state => { return state.intake.accounts },
	getSelectedAccount: state => { return state.intake.selectedAccount },
	/**
	 * get claim reasons
	 * @param {*} state - current state
	 * @returns Array of reasons
	 */
	getReasons: state => { return state.intake.reasons },
	/**
	 * get claim reason questionnaire answers
	 * @param {*} state - current state
	 * @returns Object of answers
	 */
	getReasonAnswers: state => { return state.intake.reasonAnswers },
	/**
	 * get intake questionnaire answers
	 * @param {*} state - current state
	 * @returns Object of answers
	 */
	getQuestionnaireAnswers: state => { return state.intake.questionnaireAnswers },
	/**
	 * get intake summary answers
	 * @param {*} state - current state
	 * @returns Object of answers
	 */
	getSummaryAnswers: state => { return state.intake.summaryAnswers },
	getSummaryAddress: state => { return state.intake.summaryAddress },
	getSummaryEmailAddress: state => { return state.intake.summaryEmailAddress },
	getSummarySendInfo: state => { return state.intake.summarySendInfo },
	getSummaryChannel: state => { return state.intake.summaryChannel },
	/**
	 * get claim transactions
	 * @param {*} state - current state
	 * @returns Object of answers
	 */
	getTransactions: state => { return state.intake.transactions },
	/**
	 * get claim transactions status
	 * @param {*} state - current state
	 * @returns Object of status code and how the transaction list show display
	 */
	getTransactionsStatus: state => {
		const transactionsListDisplay = (() => {
			switch (state.intake.transactionsStatusCode) {
				case '200':
					return 'required';
				case '202':
					return 'hidden';
				case '203':
					return 'optional';
				default:
					return 'unknown';
			}
		})();
		return { code: state.intake.transactionsStatusCode, transactionsListDisplay }
	},
	/**
	 * get currently selected transactions
	 * @param {*} state - current state
	 * @returns Object of transactions
	 */
	getSelectedTransactions: state => { return state.intake.selectedTransactions },
	/**
	 * get claim similar transactions
	 * @param {*} state - current state
	 * @returns Object of answers
	 */
	getSimilarTransactions: state => { return state.intake.similarTransactions },
	/**
	 * get currently selected similar transactions
	 * @param {*} state - current state
	 * @returns Object of transactions
	 */
	getSimilarSelectedTransactions: state => { return state.intake.selectedSimilarTransactions },
	/**
	 * get recognized transactions
	 * @param {*} state - current state
	 * @returns Object of transactions
	 */
	getRecognizedTransactions: state => { return state.intake.recognizedTransactions },
	/**
	 * get transaction details
	 * @param {*} state - current state
	 * @returns Object of transaction details
	 */
	getIntakeTransactionDetails: state => { return state.intake.transactionDetails },
	getCombinedIntakeTransactions: state => {
		const intakeTransactionDetails = state.intake.transactionDetails;
		return [
			...(intakeTransactionDetails.SelectedPostedTransactionList || []),
			...(intakeTransactionDetails.SelectedPendingTransactionList || []),
			...(intakeTransactionDetails.SelectedRecurringTransactionList || []),
			...(intakeTransactionDetails.DeflectedTransactionList || []),
		]
	},
	/**
	 * get check deposits
	 * @param {*} state - current state
	 * @returns Object of deposits
	 */
	getCheckDeposits: state => { return state.intake.checkDeposits },
	/**
	 * get intake questionnaire
	 * @param {*} state - current state
	 * @returns Object of questionnaire results
	 */
	getQuestionnaire: state => { return state.intake.questionnaire },
	/**
	 * get intake post questionnaire
	 * @param {*} state - current state
	 * @returns Object of post questionnaire results
	 */
	getPostQuestionnaire: state => { return state.intake.postQuestionnaire },
	/**
	 * get other accounts
	 * @param {*} state - current state
	 * @returns Array of accounts
	 */
	getOtherAccounts: state => { return state.intake.otherAccounts },
	/**
	 * get selected other account
	 * @param {*} state - current state
	 * @returns String of selected other account number
	 */
	getSelectedOtherAccount: state => { return state.intake.selectedOtherAccount },
	/**
	 * get summary
	 * @param {*} state - current state
	 * @returns Object of summary details
	 */
	getSummary: state => { return state.intake.summary },
	/**
	 * get confirmation document
	 * @param {*} state - current state
	 * @returns Object with name and data of confirmation document
	 */
	getConfirmationDocument: state => { return state.intake.confirmationDocument },
	/**
	 * get confirmation message
	 * @param {*} state - current state
	 * @returns String of confirmation message markup
	 */
	getConfirmationMessage: state => { return state.intake.confirmationMessage },
	/**
	 * get document requirements
	 * @param {*} state - current state
	 * @returns Array of requirements or false if none
	 */
	getDocRequirements: state => { return state.intake.docRequirements },
	/**
	 * get stored docs
	 * @param {*} state - current state
	 * @returns Array of docs
	 */
	getStoredDocs: state => { return state.intake.docs },
	/**
	 * get reopen summary
	 * @param {*} state - current state
	 * @returns Object of summary data
	 */
	getReopenSummary: state => { return state.reopenSummary },
	getIntakePrepopulatedData: state => { return state.intake.prepopulatedData },
};
